import { render, staticRenderFns } from "./HostingPagesSidebar.vue?vue&type=template&id=1ceec805&scoped=true&"
import script from "./HostingPagesSidebar.vue?vue&type=script&lang=js&"
export * from "./HostingPagesSidebar.vue?vue&type=script&lang=js&"
import style0 from "./HostingPagesSidebar.vue?vue&type=style&index=0&id=1ceec805&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ceec805",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
installComponents(component, {VCol,VList,VListItem,VListItemContent,VListItemGroup,VListItemTitle,VSubheader,VTab,VTabs})
